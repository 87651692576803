import { useState } from "react";
import { motion } from "framer-motion";

export default function Product() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "لحـــــــــــــــــــــــــــــــــــــــوم",
      img: "/assets/meat.png",
      alt: "meat",
    },
    {
      title: "دجـــــــــــــــــــــــــــــــــــــــاج",
      img: "/assets/shawrma.png",
      alt: "chicken",
    },
    {
      title: "صـــــــــــــــــــــــــــــــــــــــصات",
      img: "/assets/sauce.png",
      alt: "sauce",
    },
  ];

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="relative flex flex-col items-center w-full py-4 md:py-16">
      <p className="text-[#8dc341] text-3xl md:text-[150px] font-somar-sans-bold text-center mx-auto">
        منتجاتنا
      </p>
      <div className="relative w-full md:mt-[10rem]   h-[60vh] md:h-[80vh] flex items-center justify-center">
        {/* Left Button */}
        <button
          className="absolute left-4 bg-opacity-50 md:left-8 p-2 z-10 rounded-full md:px-4 md:py-3 bg-[#d7eabd]"
          onClick={handlePrev}
        >
          <img
            src="/assets/arrow.png"
            className="rotate-180  w-8 md:w-[50px]"
            alt="Previous"
          />
        </button>

        {/* Slide Content */}
        <motion.div
          key={currentSlide}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
          className="w-full h-full flex flex-col items-center justify-center relative text-center"
        >
          <p className="text-2xl md:text-[100px] text-[#2D2D72] font-somar-sans-bold font-extrabold">
            {slides[currentSlide].title}
          </p>
          <img
            src={slides[currentSlide].img}
            alt={slides[currentSlide].alt}
            className="w-[70%] md:w-[50%] h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </motion.div>

        {/* Right Button */}
        <button
          className="absolute right-4 bg-opacity-50 md:right-8 p-2 rounded-full md:px-4 md:py-3 bg-[#d7eabd]"
          onClick={handleNext}
        >
          <img
            src="/assets/arrow.png"
            className="w-8  md:w-[50px]"
            alt="Next"
          />
        </button>
      </div>
    </div>
  );
}
