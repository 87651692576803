export default function Hero() {
  return (
    <div
      className="relative h-[100vh] w-full flex items-center justify-center overflow-hidden"
      dir="rtl"
    >
      <img
        src="../assets/worker4.png"
        className="object-cover w-full h-full"
        alt="Hero Image"
      />
      <div className="absolute  inset-0 bg-yellow-500 opacity-20" />
      <div className="absolute  inset-0 flex flex-col items-start justify-center px-6 md:px-12">
        <p className="text-white text-4xl md:text-[70px] mb-4 md:mb-8">
          حلول حلتها لك
        </p>
        <div className="rounded-full bg-[#8dc341] text-white text-lg md:text-[35px] py-2 md:py-3 px-4 md:px-10">
          الحل من هنا
        </div>
      </div>
    </div>
  );
}
