

export default function WhyHulol(){
    return (
      <div
        className="flex relative h-[65vh]  mt-[4rem] w-full md:mt-[5rem] overflow-hidden  md:h-[170vh]"
        dir="rtl"
      >
        <p className="text-[#8dc341]  md:text-[150px] font-somar-sans-bold text-center mx-auto">
          لماذا حلول ؟
        </p>
        <img
          src="/assets/asset.svg"
          className="min-w-[100%] -left-[20%] mt-10 bottom-0 h-[120%] absolute "
          alt=""
        />
        <div className="flex absolute mt-[5rem] md:top-[10%] md:right-24 flex-row items-center  gap-2 ">
          <img src="/assets/coin.png" alt="" className="md:w-[90px] w-[50px]" />
          <p className="md:text-[50px] text-nowrap text-[#2D2D72]">دفع آمن</p>
        </div>
        <div className="absolute  md:top-1/2 md:left-1/2 transform top-[10rem] rotate-30 md:-translate-x-1/2 md:-translate-y-1/2  ">
          <img
            src="/assets/meat.png"
            className="md:w-full w-[250px]"
            alt="Meat"
          />
        </div>
        <div className="flex flex-row top-[14rem] left-10 md:top-[50%] md:left-24 absolute items-center gap-2  ">
          <img
            src="/assets/ice-crystal.png"
            className="md:w-[90px] w-[50px]"
            alt=""
          />
          <p className="md:text-[50px] text-nowrap  text-[#2D2D72]">
            نقل مبرد{" "}
          </p>
        </div>

        <div className="flex top-[25rem] flex-row md:top-[80%] md:right-24 absolute items-center gap-2  ">
          <img
            src="/assets/delivery-car.png"
            alt=""
            className=" md:w-[90px] w-[50px]"
          />
          <p className="md:text-[50px] text-nowrap text-[#2D2D72]">
            توصيل يومي{" "}
          </p>
        </div>
      </div>
    );
}