import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Services() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "لحوم",
      description:
        "ﻧﺨﺘﺺ ﺑﺘﻘﺪﻳﻢ ﺧﺪﻣﺎت اﻟﺘﻤﻮﻳﻦ اﻟﻐﺬاﺋﻲ ﻋﺎﻟﻴﺔ اﻟﺠﻮدة ﺣﻴﺚ ﻧﻮﻓﺮ ﻟﻚ اﻟﺼﻠﺼﺎت اﻟﻤﻐﻠﻔﺔ ﺣﺮارﻳﺎ ً ﺑﺠﻤﻴﻊ أﻧﻮاﻋﻬﺎ اﻟﺠﺎﻫﺰة او اﻟﻤﺤﻀﺮة ﺣﺴﺐ اﻟﻄﻠﺐ ﺑﻤﻜﻮﻧﺎت ﺧﺎﺻﺔ ﺑﻚ ﻓﻘﻂ, ﺑﺎﻹﺿﺎﻓﺔ اﻟﻰ اﻟﺪﺟﺎج واﻟﻠﺤﻮم واﻟﺠﺎﻫﺰة ﻟﻠﺘﺤﻀﻴﺮ ﻣﻦ ﻣﺼﻨﻌﻨﺎ ﻟﺘﻼﺋﻢ اﻻﺣﺘﻴﺎﺟﺎت وﺗﻘﻠﻞ ﺗﻜﺎﻟﻴﻒ اﻟﻤﺘﻄﻠﺒﺎت اﻟﻐﺬاﺋﻴﺔ ﻟﻠﻤﻨﺸﺌﺎت واﻟﻘﻄﺎﻋﺎت اﻟﻤﺨﺘﻠﻔﺔ.",

      image: "/assets/meat.png",
    },
    {
      title: "دجاج",
      description:
        "ﻧﺨﺘﺺ ﺑﺘﻘﺪﻳﻢ ﺧﺪﻣﺎت اﻟﺘﻤﻮﻳﻦ اﻟﻐﺬاﺋﻲ ﻋﺎﻟﻴﺔ اﻟﺠﻮدة ﺣﻴﺚ ﻧﻮﻓﺮ ﻟﻚ اﻟﺼﻠﺼﺎت اﻟﻤﻐﻠﻔﺔ ﺣﺮارﻳﺎ ً ﺑﺠﻤﻴﻊ أﻧﻮاﻋﻬﺎ اﻟﺠﺎﻫﺰة او اﻟﻤﺤﻀﺮة ﺣﺴﺐ اﻟﻄﻠﺐ ﺑﻤﻜﻮﻧﺎت ﺧﺎﺻﺔ ﺑﻚ ﻓﻘﻂ, ﺑﺎﻹﺿﺎﻓﺔ اﻟﻰ اﻟﺪﺟﺎج واﻟﻠﺤﻮم واﻟﺠﺎﻫﺰة ﻟﻠﺘﺤﻀﻴﺮ ﻣﻦ ﻣﺼﻨﻌﻨﺎ ﻟﺘﻼﺋﻢ اﻻﺣﺘﻴﺎﺟﺎت وﺗﻘﻠﻞ ﺗﻜﺎﻟﻴﻒ اﻟﻤﺘﻄﻠﺒﺎت اﻟﻐﺬاﺋﻴﺔ ﻟﻠﻤﻨﺸﺌﺎت واﻟﻘﻄﺎﻋﺎت اﻟﻤﺨﺘﻠﻔﺔ.",

      image: "/assets/shawrma.png",
    },
    {
      title: "صوصات",
      description:
        "ﻧﺨﺘﺺ ﺑﺘﻘﺪﻳﻢ ﺧﺪﻣﺎت اﻟﺘﻤﻮﻳﻦ اﻟﻐﺬاﺋﻲ ﻋﺎﻟﻴﺔ اﻟﺠﻮدة ﺣﻴﺚ ﻧﻮﻓﺮ ﻟﻚ اﻟﺼﻠﺼﺎت اﻟﻤﻐﻠﻔﺔ ﺣﺮارﻳﺎ ً ﺑﺠﻤﻴﻊ أﻧﻮاﻋﻬﺎ اﻟﺠﺎﻫﺰة او اﻟﻤﺤﻀﺮة ﺣﺴﺐ اﻟﻄﻠﺐ ﺑﻤﻜﻮﻧﺎت ﺧﺎﺻﺔ ﺑﻚ ﻓﻘﻂ, ﺑﺎﻹﺿﺎﻓﺔ اﻟﻰ اﻟﺪﺟﺎج واﻟﻠﺤﻮم واﻟﺠﺎﻫﺰة ﻟﻠﺘﺤﻀﻴﺮ ﻣﻦ ﻣﺼﻨﻌﻨﺎ ﻟﺘﻼﺋﻢ اﻻﺣﺘﻴﺎﺟﺎت وﺗﻘﻠﻞ ﺗﻜﺎﻟﻴﻒ اﻟﻤﺘﻄﻠﺒﺎت اﻟﻐﺬاﺋﻴﺔ ﻟﻠﻤﻨﺸﺌﺎت واﻟﻘﻄﺎﻋﺎت اﻟﻤﺨﺘﻠﻔﺔ.",
      image: "/assets/sauce.png",
    },
  ];

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const handlePrev = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="w-full text-center md:mb-[10rem] mb-[2rem] md:mt-[4rem]">
      <p className="text-[#8dc341] md:mt-[10rem] mt-[3rem] text-3xl md:text-[150px] font-somar-sans-bold">
        خدماتنا
      </p>

      <div className="relative mt-4 md:mt-[7rem] w-full flex items-center justify-center">
        <div className="w-full">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col md:flex-row items-center justify-center md:justify-between"
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`flex flex-col md:flex-row items-center w-full ${
                    currentSlide === index ? "block" : "hidden"
                  }`}
                >
                  <div className="relative w-full md:w-1/2 flex justify-center items-center">
                    <img
                      src={slide.image}
                      className="w-full md:w-[60%] object-cover"
                      alt={slide.title}
                    />
                    <img
                      src="/assets/asset.svg"
                      className="absolute inset-0 z-0 object-cover w-full h-full"
                      alt=""
                    />
                  </div>

                  <div className="flex flex-col items-center md:items-end w-full md:w-1/2 px-4 md:px-16 py-8 md:py-0">
                    <p className="text-[#8dc341]  text-4xl md:text-[120px] font-somar-sans-bold mb-4 text-center md:text-end">
                      {slide.title}
                    </p>
                    <p className="text-[#2D2D72] md:mt-[5rem] text-lg md:text-2xl text-center md:text-end">
                      {slide.description}
                    </p>
                  </div>

                  <div className="absolute top-1/2 right-0 transform -translate-y-1/2 md:relative md:top-auto md:right-auto md:translate-y-0">
                    <button
                      className="rounded-full md:px-4 bg-opacity-50 md:py-3 bg-[#d7eabd]"
                      onClick={handleNext}
                    >
                      <img
                        src="/assets/arrow.png"
                        className="w-12 md:w-[100px]"
                        alt="Next"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
